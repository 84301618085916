import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from '../components/seo/seoall';
import adPlaceholder1 from "../images/placeholder/home-ad-1-placeholder.png";
import adPlaceholder2 from "../images/placeholder/home-ad-1-placeholder-square.png";
import hAdPlaceholder from "../images/placeholder/horizontal-ad-placeholder-noborder.png";


const IndexPage = () => {


  const data = useStaticQuery(graphql`
    query {
        recent: allBlogDataJson(limit: 8, sort: {order: DESC, fields: date}) {
            edges {
                node {
                    slug
                    H1
                    excerpt
                    IMAGE_URL
                    date
                }
            }
        }

        trending: allCategoriesJson(
            filter: {category: {eq: "Trending"}}
            sort: {fields: content___posts___date, order: DESC}
            limit: 6
        ) {
            edges {
                node {
                    category
                    content {
                        name
                        slug
                        posts {
                            H1
                            slug
                            IMAGE_URL
                            date
                            excerpt
                        }
                    }
                }
            }
        }

        popular: allCategoriesJson(
            filter: {category: {eq: "Popular"}}
            sort: {fields: content___posts___date, order: DESC}
            limit: 6
        ) {
            edges {
                node {
                    category
                    content {
                        name
                        slug
                        posts {
                            H1
                            slug
                            IMAGE_URL
                            date
                            excerpt
                        }
                    }
                }
            }
        }

        allAdDataJson {
            edges {
                node {
                    link
                    image
                    type
                    image_alt
                }
            }
        }
      
        allDescriptionsJson(filter: {page_location: {eq: "homepage"}}) {
          nodes {
            page_location
            description
              }
            }
          
    }
  `);


  if (data.errors) {
    console.log("Error retrieving data", data.errors);
    return;
  }


  return (
    <div>

      <Layout classes="grey-bg">
      <Seo description={data.allDescriptionsJson.nodes[0].description} slug='' category={data.allDescriptionsJson.nodes[0].page_location} />

        <div className="container-fluid p-0">

          <div className="row single-post m-auto justify-content-between mt-5">
            <div className="col-12 col-md-12 col-lg-8 long-container">
              {
                data.recent.edges.slice(0, 6).map((el, i) => {
                  return (
                    <div className="card-body mb-3 p-md-0" key={`rp-${i}`}>
                      <Link to={`/${el.node.slug}`} className='linkcard'>
                        <div className="d-flex align-items-center">
                          <div className="image-wrapper">
                            <img className="" src={el.node.IMAGE_URL} alt={el.node.H1} />
                          </div>
                          <div className="content-wrapper">
                            <h2 className="card-title">{el.node.H1}</h2>
                            <p className="card-text">{el.node.excerpt.substring(0,100)}...</p>

                            <div  className="card-link">Read More →</div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })
              }
            </div>
            <div className="col-12 col-md-12 col-lg-4 mb-5 mb-md-5 mb-lg-0">
              <div className="row align-items-center">
                <div className="col-12 col-md-6 col-lg-12 mx-md-auto my-4 my-lg-0">
                  <div className='home-ad-wrapper mb-3'>
                    {
                      data.allAdDataJson.edges.filter((el) => {
                        return el.node.type === "homepage_top";
                      }).map((el, i) => {
                        return(
                          <a href={el.node.link} target='_blank' className='ad-link' key={`adl-${i}`}>
                            <img src={el.node.image === ""? adPlaceholder2: el.node.image} className='ad-image' alt={el.node.image_alt}/>
                          </a>
                        )
                      })
                    }
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12 trending-side-wrapper">
                  <div className="row">
                    {
                      data.recent.edges.slice(6, 8).map((el, i) => {
                        return (
                          <div className={`col-12 col-md-6 col-lg-12 ${i === 0? 'mb-3 mb-md-0 mb-lg-3': ''}`} key={`rp2-${i}`}>
                            <div className="card border-radius-0 h-100 border-0">
                              <img className="card-img-top h-100 min-250" src={el.node.IMAGE_URL} alt={el.node.H1} />
                              <div className="card-body bg-white">
                                <Link to={`/${el.node.slug}`} className='linkcard'>
                                  <h2 className="card-title">{el.node.H1}</h2>
                                  <p className="card-text">{el.node.excerpt.substring(0,100)}...</p>
                                </Link>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>


            </div>
          </div>

          <div className="row single-post m-auto">
            <div className="col-12">
              <div className="max-ad-width">
                <div className='horizontal-ad-wrapper primary-border'>
                  {
                    data.allAdDataJson.edges.filter((el) => {
                      return el.node.type === "homepage_mid";
                    }).map((el, i) => {
                      return(
                        <a href={el.node.link} target='_blank' className='ad-link' key={`adl-${i}`}>
                          <img src={el.node.image === ""? hAdPlaceholder: el.node.image} className='ad-image' alt={el.node.image_alt}/>
                        </a>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="row single-post m-auto">
            <div className="col-12 mb-4">
              <h2 className="block-title">Trending Articles</h2>
            </div>
          </div>

          <div className="row single-post m-auto popular-article-content">
            {
              data.trending.edges[0].node.content[1].posts.slice(0, 4).map((el, i) => {
                return (
                  <div className="col-12 col-md-12 col-lg-6" key={`pp-${i}`}>
                    <div className={`card-body p-0 ${i !== 3? 'mb-3': ''} ${i === 2? 'mb-lg-0': ''}`}>
                      <Link to={`/${el.slug}`} className='linkcard'>
                        <div className="d-flex align-items-center">
                          <div className="image-wrapper">
                            <img className="" src={el.IMAGE_URL} alt={el.H1} />
                          </div>
                          <div className="content-wrapper">
                            <h2 className="card-title">{el.H1}</h2>
                            <p className="card-text">{el.excerpt.substring(0,100)}...</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              })
            }
          </div>


          <div className="row single-post m-auto justify-content-between pt-4 my-5">
            <div className="col-12 col-md-12 col-lg-4 mb-5 mb-md-5 mb-lg-0">
              <div className="row align-items-center">
                <div className="col-12 col-md-6 col-lg-12">
                  <div className='home-ad-wrapper mb-3'>
                    {
                      data.allAdDataJson.edges.filter((el) => {
                        return el.node.type === "homepage_bottom_1";
                      }).map((el, i) => {
                        return(
                          <a href={el.node.link} target='_blank' className='ad-link' key={`adl-${i}`}>
                            <img src={el.node.image === ""? adPlaceholder2: el.node.image} className='ad-image' alt={el.node.image_alt}/>
                          </a>
                        )
                      })
                    }
                  </div>
                </div>
                {
                  data.popular.edges[0].node.content[1].posts.slice(7, 11).map((el, i) => {
                    return (
                      <div className="col-12 col-md-6 col-lg-12">
                        <div className="card-body mb-3 bg-white">
                          <Link to={`/${el.slug}`} className='linkcard'>
                            <h2 className="card-title">{el.H1}</h2>
                            <p className="card-text">{el.excerpt.substring(0,100)}...</p>
                            <div  className="card-link">Read More →</div>
                          </Link>
                        </div>
                      </div>
                    )
                  })
                }
                <div className="col-12 col-md-6 col-lg-12">
                  <div className='home-ad-wrapper'>
                    {
                      data.allAdDataJson.edges.filter((el) => {
                        return el.node.type === "homepage_bottom_2";
                      }).map((el, i) => {
                        return(
                          <a href={el.node.link} target='_blank' className='ad-link' key={`adl-${i}`}>
                            <img src={el.node.image === ""? adPlaceholder2: el.node.image} className='ad-image' alt={el.node.image_alt}/>
                          </a>
                        )
                      })
                    }
                  </div>
                </div>
              </div>


            </div>
            <div className="col-12 col-md-12 col-lg-8 long-container">
              <h2 className="block-title-big">Popular Articles</h2>
              {
                data.popular.edges[0].node.content[1].posts.slice(0, 7).map((el, i) => {
                  return (
                    <div className="card-body mb-3 p-md-0">
                      <Link to={`/${el.slug}`} className='linkcard'>
                        <div className="d-flex align-items-center">
                          <div className="image-wrapper">
                            <img className="" src={el.IMAGE_URL} alt={el.H1} />
                          </div>
                          <div className="content-wrapper">
                            <h2 className="card-title">{el.H1}</h2>
                            <p className="card-text">{el.excerpt.substring(0,100)}...</p>
                            <div  className="card-link">Read More →</div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })
              }
            </div>
          </div>

        </div>
      </Layout>
    </div>
  );
};

export default IndexPage;
